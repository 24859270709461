.table-tiny th,
.table-tiny td {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid var(--chakra-colors-gray-200);
}

.table-tiny th {
  text-align: center;
}

.table-tiny th:last-child {
/*.tiny-table-last-td*/
  text-align: right;
}

.table-tiny td {
  padding-left: 8px;
  padding-right: 8px;
  text-align: right;
}

.table-tiny td:first-child {
  text-align: left;
}


